body {
  overscroll-behavior: contain;
  overflow: auto;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  scrollbar-width: thin; /* FF-only accepts auto, thin, none */
  scrollbar-color: rgba(128, 128, 128, 0.6) transparent !important;
}

::-webkit-scrollbar {
  width: 16px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-track:hover {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(128, 128, 128, 0.6) !important;
  border-radius: 10px;
  border: 5px solid rgba(0, 0, 0, 0.01);
  background-clip: padding-box;
}

::-webkit-scrollbar-thumb:hover,
::-webkit-scrollbar-thumb:active {
  background-color: transparent;
  border: 4px solid rgba(0, 0, 0, 0.01);
}
